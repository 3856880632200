import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactSuccessPage = () => (
  <Layout>
    <SEO title="Contact Success" />
    <section className="intro">
      <h2>Contact</h2>
      <p>Thank you for your message! I'll be in touch soon. <Link to="/">Go back home</Link></p>
    </section>
  </Layout>
)

export default ContactSuccessPage
